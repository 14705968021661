import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Heading from '../atoms/BaseHeading'
import Txt from '../atoms/BaseTxt'
import Anchor from '../atoms/BaseAnchor'
import Bnr from '../organisms/TheSideBnr'

// template
const TheSideBar = (props) => (
  <StaticQuery
    query={pageQuery}
    render={(data) => (
      <SideBar>
        <TitleBox>
          <Title level={2} className="title-module">
            カテゴリーから探す
          </Title>
        </TitleBox>

        <ContentBox>
          <CatBox className="cat-cover">
            {data.catOne.edges.map(({ node }) => (
              <div key={node.id} className="cat-item">
                <CatCheckBox
                  id={`cat_${node.id}`}
                  defaultChecked={
                    props.catOne === node.frontmatter.cat ? true : false
                  }
                />
                <CatTitle
                  type={'label'}
                  className={
                    props.catOne === node.frontmatter.cat
                      ? 'cat-active cat_label'
                      : 'cat_label'
                  }
                  htmlFor={`cat_${node.id}`}
                >
                  {node.frontmatter.title}
                  <CatIcon className="cat-icon" />
                </CatTitle>
                <CatBox className="cat-inner">
                  <div className="cat-item cat-levelOne">
                    <Anchor
                      to={node.fields.slug}
                      cls="side"
                      className={
                        props.catOne === node.frontmatter.cat && !props.catTwo
                          ? 'cat-active'
                          : 'cat-inactive'
                      }
                    >
                      <Txt type={'span'}>{node.frontmatter.title} 全て</Txt>
                    </Anchor>
                  </div>
                  {data.catTwo.edges
                    .filter((cat) =>
                      cat.node.fields.slug.startsWith(
                        `/${node.frontmatter.cat}/`
                      )
                    )
                    .map(({ node }) => (
                      <div key={node.id} className="cat-item cat-levelTwo">
                        <Anchor
                          to={node.fields.slug}
                          cls="side"
                          className={
                            `/${props.catOne}/${props.catTwo}/` ===
                            node.fields.slug
                              ? 'cat-active'
                              : 'cat-inactive'
                          }
                        >
                          <Txt type={'span'}>{node.frontmatter.title}</Txt>
                        </Anchor>
                      </div>
                    ))}
                </CatBox>
              </div>
            ))}
          </CatBox>

          <Bnr />
        </ContentBox>
      </SideBar>
    )}
  />
)

export default TheSideBar

// script
export const pageQuery = graphql`
  query {
    catOne: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: {
        frontmatter: { kind: { eq: "faq" }, posttype: { eq: "catOne" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            kind
            posttype
            cat
            order
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
    catTwo: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: {
        frontmatter: { kind: { eq: "faq" }, posttype: { eq: "catTwo" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            kind
            posttype
            cat
            order
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

// style
const SideBar = styled.aside`
  height: 100%;
  ${media.Large`
		height: auto;
	`}
`
const TitleBox = styled.div`
  align-items: center;
  display: flex;
  height: 3.2rem;
  padding-left: ${vc_base.pixel`1.25`};
  padding-right: ${vc_base.pixel`1.25`};
  ${media.Large`
		height: auto;
		display: block;
		align-items: unset;
		padding-left: 0;
		padding-right: 0;
	`}
`
const Title = styled(Heading)`
  border-left: 4px solid ${vc_color.primary};
  padding-left: ${vc_base.pixel`1.25`};
`
const ContentBox = styled.div`
  height: calc(100% - 3.2rem);
  overflow: auto;
  ${media.Large`
		height: auto;
	`}
`
const CatBox = styled.div`
  &.cat-cover {
    ${media.Large`
			margin-top: ${vc_base.pixel`1.5`};
		`}
    > .cat-item {
      margin-top: ${vc_base.pixel`0.5`};
    }
  }
  &.cat-inner {
    > .cat-item {
      height: 0;
      overflow: hidden;
      transition: all ${vc_base.speed_quick};
      &.cat-levelOne {
        span {
          line-height: 1;
        }
      }
      &.cat-levelTwo {
        a {
          span {
            line-height: 1;
            padding-left: 2em;
            position: relative;
            z-index: 1;
            &::before {
              border-bottom: 1px solid ${vc_color.border};
              border-left: 1px solid ${vc_color.border};
              content: '';
              height: 0.5em;
              left: 1em;
              position: absolute;
              top: 50%;
              transform: translateY(-75%);
              width: 0.5em;
              z-index: 1;
            }
          }
        }
      }
      .side {
        align-items: center;
        display: flex;
        height: 100%;
        padding-left: ${vc_base.pixel`1.5`};
        padding-right: ${vc_base.pixel`1.5`};
      }
    }
  }
`
const CatCheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
  &:checked ~ .cat_label {
    .cat-icon {
      transform: rotate(-180deg);
    }
  }
  &:checked ~ .cat-inner {
    > .cat-item {
      border-top: 1px dotted ${vc_color.border};
      height: ${vc_base.pixel`5`};
      padding-bottom: ${vc_base.pixel`0.25`};
      padding-top: ${vc_base.pixel`0.25`};
      &:first-child {
        border-top: none;
      }
    }
  }
`
const CatTitle = styled(Txt)`
  align-items: center;
  background: ${vc_color.bg_light};
  border-radius: ${vc_base.pixel`0.5`};
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: ${vc_base.pixel`6`};
  padding-left: ${vc_base.pixel`1.5`};
  padding-right: ${vc_base.pixel`1.5`};
  position: relative;
  z-index: 1;
  &.cat-active {
    background: ${vc_color.primary_light};
  }
  &:hover {
    background: ${vc_color.side_bg_hover};
  }
`
const CatIcon = styled.span`
  border-left: ${vc_base.pixel`0.5`} solid transparent;
  border-right: ${vc_base.pixel`0.5`} solid transparent;
  border-top: ${vc_base.pixel`0.5`} dashed ${vc_color.text_gray};
  display: inline-block;
  height: 0;
  margin-left: 2px;
  position: absolute;
  right: ${vc_base.pixel`2`};
  top: 50%;
  transition: all ${vc_base.speed_quick} ease-out;
  vertical-align: middle;
  width: 0;
  z-index: 1;
`
