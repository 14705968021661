import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { config, dom, library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronUp,
  faSearch,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'
config.showMissingIcons = false
library.add(faChevronUp, faSearch, faQuestionCircle)
dom.i2svg()

// Presenter
const Icon = (props) => (
  <FontAwesomeIcon
    icon={props.icon}
    className={props.size}
    style={props.style}
  />
)

export default Icon
