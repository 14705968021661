import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Txt from '../atoms/BaseTxt'
import Caption from '../atoms/BaseCaption'
import Anchor from '../atoms/BaseAnchor'

// template
const TheFooter = (props) => (
  <StaticQuery
    query={footer}
    render={(data) => (
      <Footer {...props}>
        <div className={props.innerCls ? props.innerCls : 'footer-inner'}>
          <FooterInnerBox>
            <FooterLogo>
              <Anchor
                type={'a'}
                href="https://www.valuecommerce.ne.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoImg
                  image={data.footerLogo.childImageSharp.gatsbyImageData}
                  alt="バリューコマース アフィリエイト管理画面ヘルプ"
                />
              </Anchor>
            </FooterLogo>

            <FooterInfo>
              <Upper>
                <UpperItem>
                  <Anchor
                    type={'a'}
                    href="https://www.valuecommerce.co.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Txt type={'span'} className="anchor-text">
                      会社概要
                    </Txt>
                  </Anchor>
                </UpperItem>
                <UpperItem>
                  <Anchor
                    type={'a'}
                    href="https://www.valuecommerce.co.jp/service/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Txt type={'span'} className="anchor-text">
                      サービス
                    </Txt>
                  </Anchor>
                </UpperItem>
                <UpperItem>
                  <Anchor
                    type={'a'}
                    href="https://www.valuecommerce.co.jp/recruit/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Txt type={'span'} className="anchor-text">
                      採用情報
                    </Txt>
                  </Anchor>
                </UpperItem>
                <UpperItem>
                  <Anchor
                    type={'a'}
                    href="https://www.valuecommerce.co.jp/privacy_policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Txt type={'span'} className="anchor-text">
                      個人情報保護方針
                    </Txt>
                  </Anchor>
                </UpperItem>
              </Upper>

              <Lower>
                <Caption>
                  <small>© ValueCommerce Co., Ltd.</small>
                </Caption>
              </Lower>
            </FooterInfo>
          </FooterInnerBox>
        </div>
      </Footer>
    )}
  />
)

export default TheFooter

// script
export const footer = graphql`
  {
    footerLogo: file(relativePath: { eq: "footerLogo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 160
          placeholder: TRACED_SVG
          layout: CONSTRAINED
        )
      }
    }
  }
`

// style
const Footer = styled.footer`
  border-top: 1px solid ${vc_color.border};
  padding-bottom: ${vc_base.pixel`2.5`};
  padding-top: ${vc_base.pixel`2.5`};
`
const FooterInnerBox = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  ${media.Medium`
		flex-wrap: nowrap;
		margin-left:  -${vc_base.pixel};
		margin-right: -${vc_base.pixel};
	`}
`
const FooterLogo = styled.div`
  width: 100%;
  ${media.Medium`
		width: auto;
		padding-left: ${vc_base.pixel};
		padding-right: ${vc_base.pixel};
	`}
`
const LogoImg = styled(GatsbyImage)`
  width: 160px;
`
const FooterInfo = styled.div`
  width: 100%;
  ${media.Medium`
		width: auto;
		padding-left: ${vc_base.pixel};
		padding-right: ${vc_base.pixel};
	`}
`
const Upper = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${vc_base.pixel};
  ${media.Medium`
		margin-top: 0;
	`}
`
const UpperItem = styled.li`
  padding-left: 1em;
  padding-right: 1em;
`
const Lower = styled.div`
  margin-top: ${vc_base.pixel};
  padding-left: 1em;
  padding-right: 1em;
  ${media.Medium`
		margin-top: 0;
	`}
`
