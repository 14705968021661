import React from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Anchor from '../atoms/BaseAnchor'

// template
const Bnr = (props) => (
  <BnrTag type={props.type} {...props}>
    {props.children}
  </BnrTag>
)

export default Bnr

// style
const BnrTag = styled(Anchor)`
  border: 1px solid ${vc_color.border};
  display: block;
  &.base-text {
    padding-bottom: ${vc_base.pixel};
    padding-left: ${vc_base.pixel`1.5`};
    padding-right: ${vc_base.pixel`1.5`};
    padding-top: ${vc_base.pixel};
  }
`
