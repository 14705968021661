import React from 'react'

// components
import TxtBox from '../atoms/BaseTxtBox'
import { connectSearchBox } from 'react-instantsearch-dom'

const SearchFormAlgolia = ({ currentRefinement, refine }) => (
  <TxtBox
    className="search__input"
    type="search"
    value={currentRefinement}
    autoFocus={false}
    showLoadingIndicator
    searchAsYouType
    onChange={(event) => refine(event.currentTarget.value)}
    placeholder="キーワードを入力してください"
  />
)

export default connectSearchBox(SearchFormAlgolia)
