import React from 'react'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Caption from '../atoms/BaseCaption'

// template
const PickUpListAlgolia = (props) => (
  <PickUp>
    {props.pickupkeywords.map((word, index) => (
      <PickUpItem key={index} onClick={props.clickQueryList}>
        <Caption>{word}</Caption>
      </PickUpItem>
    ))}
  </PickUp>
)

export default PickUpListAlgolia

// style
const PickUp = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${vc_base.pixel};
  margin-right: -${vc_base.pixel};
  ${media.Large`
		flex-wrap: nowrap;
		margin-left: 0;
		margin-right: 0;
	`}
`
const PickUpItem = styled.li`
  color: ${vc_color.link};
  cursor: pointer;
  padding-bottom: ${vc_base.pixel`0.25`};
  padding-left: ${vc_base.pixel};
  padding-right: ${vc_base.pixel};
  padding-top: ${vc_base.pixel`0.25`};
  ${media.Large`
		padding-top: 0;
		padding-bottom: 0;

		&:hover,
		&:active {
			color: ${vc_color.link_hover};
			text-decoration: underline;
		}
	`}
`
