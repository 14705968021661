import React from 'react'
import styled from 'styled-components'
import { GlobalStyle } from '../../assets/js/GlobalStyle'
import { media } from '../../assets/js/Helper'
import { vc_base } from '../../assets/js/Brand'

// components
import HamburgerMenu from '../molecules/HamburgerMenu'
import PageTop from '../molecules/PageTop'
import Overlay from '../molecules/Overlay'
import Header from '../organisms/TheHeader'
import Footer from '../organisms/TheFooter'
import Sidebar from '../organisms/TheSideBar'
import Search from '../organisms/TheSearchAlgolia'

// template
const Default = (props) => (
  <React.Fragment>
    <GlobalStyle />
    <Cover>
      <div>
        <HamburgerMenu />
        <Header className="header" innerCls="container header-inner" />
        <Body className="body container">
          <Search />
          <BodyInner>
            <Main className="main">{props.children}</Main>
            <Side className="side">
              <Sidebar catOne={props.catOne} catTwo={props.catTwo} />
            </Side>
          </BodyInner>
        </Body>
        <FooterBox>
          <Footer className="footer" innerCls="container footer-inner" />
        </FooterBox>
        <PageTop position="fixedRightBottom" />
        <Overlay overlayFor="hamburger" />
      </div>
    </Cover>
  </React.Fragment>
)

export default Default

// style
const Cover = styled.div`
  margin-bottom: 5em;
  padding-top: 4.4rem;
  .hamburger-checkbox {
    &:checked ~ .hamburger {
      left: 28.5rem;
      & span {
        background: #fff;
      }
    }
    &:checked ~ div .side {
      transform: translateX(0);
    }
  }
  ${media.Large`
    padding-top: 0;
    margin-bottom: 10em;
		.hamburger-checkbox,
		.hamburger,
		.overlay {
			display: none !important;
		}
	`}
`
const FooterBox = styled.div`
  bottom: 0;
  height: 5em;
  padding-top: ${vc_base.pixel`2.5`};
  position: absolute;
  width: 100%;
  ${media.Large`
    height: 10em;
    padding-top: ${vc_base.pixel`5`};
	`}
`
const Body = styled.div`
  margin-top: ${vc_base.pixel`2.5`};
  ${media.Large`
    margin-top: ${vc_base.pixel`5`};
	`}
`
const BodyInner = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: ${vc_base.pixel`2.5`};
  ${media.Large`
    margin-top: ${vc_base.pixel`5`};
    margin-left: -${vc_base.pixel`2.5`};
    margin-right: -${vc_base.pixel`2.5`};
	`}
`
const Main = styled.div`
  width: 100%;
  ${media.Large`
    width: calc(100% - 28.5rem);
    padding-left: ${vc_base.pixel`2.5`};
    padding-right: ${vc_base.pixel`2.5`};
	`}
`
const Side = styled.div`
  background: #fff;
  height: 100%;
  left: 0;
  padding-left: ${vc_base.pixel`0.5`};
  padding-right: ${vc_base.pixel`0.5`};
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: all 0.25s ease-out;
  width: 28.5rem;
  z-index: 100;
  ${media.Large`
    height: auto;
    padding-left: ${vc_base.pixel`2.5`};
    padding-right: ${vc_base.pixel`2.5`};
    position: relative;
    top: unset;
    left: unset;
    transform: translateX(0);
    height: auto;
    z-index: auto;
    background: none;
	`}
`
