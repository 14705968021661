import React from 'react'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'

// template
const HamburgerMenu = (props) => (
  <React.Fragment>
    <CheckBox
      id="hamburger"
      className={props.clsCheckBox ? props.clsCheckBox : 'hamburger-checkbox'}
    />
    <Hamburger
      className={props.clsHamburger ? props.clsHamburger : 'hamburger'}
      htmlFor="hamburger"
      position={props.position}
    >
      <HamburgerBorder
        className={
          props.clsHamburgerBorder
            ? props.clsHamburgerBorder
            : 'hamburger-border'
        }
      />
      <HamburgerBorder
        className={
          props.clsHamburgerBorder
            ? props.clsHamburgerBorder
            : 'hamburger-border'
        }
      />
      <HamburgerBorder
        className={
          props.clsHamburgerBorder
            ? props.clsHamburgerBorder
            : 'hamburger-border'
        }
      />
    </Hamburger>
  </React.Fragment>
)

export default HamburgerMenu

// style
const CheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
  &:checked ~ .hamburger .hamburger-border {
    &:nth-of-type(1) {
      margin-top: 0;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
    &:nth-of-type(2) {
      margin-left: -5px;
      opacity: 0;
    }
    &:nth-of-type(3) {
      margin-top: 0;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
  }
  &:checked ~ .overlay {
    display: block;
  }
`
const Hamburger = styled.label`
  cursor: pointer;
  display: block;
  height: 44px;
  left: 0;
  margin: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  transition: all 0.25s ease-out;
  width: 44px;
  z-index: 15;
  ${media.Large`
		position: absolute;
	`}
`
const HamburgerBorder = styled.span`
  background: #000;
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.25s ease-out;
  width: 20px;
  z-index: 10;
  &:nth-of-type(1) {
    margin-top: -5px;
  }
  &:nth-of-type(3) {
    margin-top: 5px;
  }
`
