import React from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'

// Presenter
const TxtBox = (props) => {
  return <TxtInput className={[props.className]} ref={props.myRef} {...props} />
}

export default TxtBox

// style
const TxtInput = styled.input.attrs({
  type: 'text',
})`
  border: 1px solid ${vc_color.border};
  border-radius: ${vc_base.pixel`0.5`};
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1;
  padding: 0.75em;
  width: 100%;
`
