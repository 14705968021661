import React, { useState } from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'
import { media } from '../../assets/js/Helper'
import pickup from '../../../content/pickup.json'

// components
import Heading from '../atoms/BaseHeading'
import Caption from '../atoms/BaseCaption'
import Icon from '../atoms/BaseIcon'
import Overlay from '../molecules/Overlay'
import SearchForm from '../molecules/SearchFormAlgolia'
import SearchResult from '../molecules/SearchResultAlgolia'
import PickUpList from '../molecules/PickUpListAlgolia'

import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

// Algoliaの設定
const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const algoliaIndexName = process.env.GATSBY_ALGOLIA_INDEX_NAME

// template
const TheSearchAlgolia = () => {
  const [query, setQuery] = useState('')
  const [focus, setFocus] = useState(false)
  const [pickupkeywords] = useState(pickup[0].keywords)

  const handleSetQuery = (query) => {
    setQuery(query)
    setFocus(true)
  }

  const handleSetFocus = (bool) => {
    setFocus(bool)
  }

  const handleClickQueryList = (event) => {
    const query = event.target.innerText
    handleSetFocus(true)
    handleSetQuery(query)
  }

  const handleOverlay = () => {
    handleSetFocus(false)
  }

  return (
    <Search>
      <SearchContainer>
        <Title level={2} className="title-module">
          <Icon
            icon="search"
            style={{
              width: `18px`,
              height: `18px`,
              fontSize: `1em`,
              marginRight: `0.5em`,
            }}
          />
          キーワードから探す
        </Title>
        <SearchFormContainer>
          <PickUpBox>
            <PickUpInner>
              <PickUpTitle>ピックアップキーワード：</PickUpTitle>
              <PickUpList
                clickQueryList={handleClickQueryList}
                pickupkeywords={pickupkeywords}
              />
            </PickUpInner>
          </PickUpBox>
          <InstantSearch
            searchClient={client}
            searchState={{ query: query }}
            onSearchStateChange={({ query }) => handleSetQuery(query)}
            indexName={algoliaIndexName}
          >
            <Configure attributesToSnippet={['excerpt: 40']} />
            <SearchFormBox>
              <SearchForm />
              {query.length > 0 && focus && (
                <SearchResultBox>
                  <SearchResult query={query} style={{ maxHeight: '256px' }} />
                </SearchResultBox>
              )}
            </SearchFormBox>
          </InstantSearch>
        </SearchFormContainer>

        {query.length > 0 && focus && (
          <SearchOverlay overlayFor="search" onClick={handleOverlay} />
        )}
      </SearchContainer>
    </Search>
  )
}

export default TheSearchAlgolia

// style
const Search = styled.section`
  background: ${vc_color.bg_dark};
  border: 1px solid ${vc_color.border};
  border-radius: ${vc_base.pixel`0.5`};
  padding-bottom: ${vc_base.pixel`1.5`};
  padding-left: ${vc_base.pixel`1.5`};
  padding-right: ${vc_base.pixel`1.5`};
  padding-top: ${vc_base.pixel`1.5`};
  ${media.Large`
		padding-top: ${vc_base.pixel`2.5`};
		padding-bottom: ${vc_base.pixel`2.5`};
		padding-left: ${vc_base.pixel`2.5`};
		padding-right: ${vc_base.pixel`2.5`};
	`}
`
const SearchContainer = styled.div`
  margin-left: -${vc_base.pixel`1.25`};
  margin-right: -${vc_base.pixel`1.25`};
  width: auto;
  ${media.Large`
		display: flex;
		flex-wrap: nowrap;
	`}
`
const Title = styled(Heading)`
  align-items: center;
  display: flex;
  margin-bottom: ${vc_base.pixel};
  padding-left: ${vc_base.pixel`1.25`};
  padding-right: ${vc_base.pixel`1.25`};
  white-space: nowrap;
  ${media.Large`
		margin-bottom: 0;
		min-width: 11.5em;
	`}
`
const SearchFormContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 12;
`
const SearchFormBox = styled.div`
  align-items: center;
  display: flex;
  padding-left: ${vc_base.pixel`1.25`};
  padding-right: ${vc_base.pixel`1.25`};
  position: relative;
  width: 100%;
  z-index: 2;
`
const SearchResultBox = styled.div`
  left: 0;
  padding-left: ${vc_base.pixel`1.25`};
  padding-right: ${vc_base.pixel`1.25`};
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
  z-index: 2;
`
const PickUpBox = styled.div`
  margin-bottom: ${vc_base.pixel`0.5`};
  padding-left: ${vc_base.pixel`1.25`};
  padding-right: ${vc_base.pixel`1.25`};
`
const PickUpInner = styled.div`
  ${media.Large`
		display: flex;
	`}
`
const PickUpTitle = styled(Caption)`
  font-weight: bold;
  margin-bottom: ${vc_base.pixel`0.5`};
  ${media.Large`
		margin-bottom: 0;
		display: flex;
	`}
`
const SearchOverlay = styled(Overlay)`
  display: block !important;
  opacity: 0;
  z-index: 10;
`
