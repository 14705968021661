import React from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Icon from '../atoms/BaseIcon'

// template
const PageTop = (props) => {
  const handleClick = (e) => {
    e.preventDefault()
    let begin = new Date() - 0
    let duration = 250
    let currentY = window.pageYOffset
    let timer = setInterval(function () {
      let current = new Date() - begin
      if (current > duration) {
        clearInterval(timer)
        current = duration
      }
      //スクロール位置を単位時間で変更する
      window.scrollTo(0, currentY * (1 - current / duration))
    }, 10)
  }

  return (
    <PageTopTag
      className={props.cls ? props.cls : 'pageTop'}
      onClick={handleClick}
      position={props.position}
    >
      <Icon icon="chevron-up" size="fa-2x" style={{ color: `#fff` }} />
    </PageTopTag>
  )
}

export default PageTop

// style
const pageTopPosition = (props) => {
  if (props.position === 'fixedLeftBottom') {
    return `
			position: fixed;
			left: 20px;
			bottom: 20px
    `
  } else if (props.position === 'fixedRightBottom') {
    return `
			position: fixed;
			right: 20px;
			bottom: 20px;
    `
  }
}

const PageTopTag = styled.div`
  align-items: center;
  background: ${vc_color.primary};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: ${vc_base.pixel`5.5`};
  justify-content: center;
  width: ${vc_base.pixel`5.5`};
  z-index: 5;
  ${(props) => pageTopPosition(props)}
`
