import React from 'react'
import styled from 'styled-components'

// template
const Overlay = (props) => (
  <OverlayTag className="overlay" htmlFor={props.overlayFor} {...props} />
)

export default Overlay

// style
const OverlayTag = styled.label`
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 14;
`
