import React from 'react'
import { containPresenter } from '../../utils/HoC'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'

// Container
export const ParagraphContainer = ({
  presenter,
  type = 'p',
  role = 'default',
  ...props
}) => {
  const tag = type
  return presenter({ tag, role, ...props })
}

// Presenter
const ParagraphPresenter = ({ tag: Tag, role, className, ...props }) => {
  return <Tag className={[role, className].join(' ')} {...props} />
}

// style
const StyledParagraphPresenter = styled(ParagraphPresenter)`
  &.default {
    font-size: 1.4rem;
    line-height: 1.75;
    ${media.Large`
      font-size: 1.5rem;
    `}
  }
  &.detail {
    font-size: 1.6rem;
    line-height: 2;
    ${media.Large`
      font-size: 1.7rem;
    `}
  }
  &.large {
    font-size: 1.5rem;
    ${media.Large`
      font-size: 1.8rem;
    `}
  }
`

const Txt = containPresenter(ParagraphContainer, StyledParagraphPresenter)
export default Txt
