import React from 'react'
import { containPresenter } from '../../utils/HoC'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'
import { vc_color } from '../../assets/js/Brand'

// Container
export const AnchorContainer = ({ presenter, type = Link, cls, ...props }) => {
  const tag = type
  return presenter({ tag, cls, ...props })
}

// Presenter
export const AnchorPresenter = ({ tag: Tag, cls, className, ...props }) => {
  return <Tag className={[cls, className].join(' ')} {...props} />
}

// style
const StyledAnchorPresenter = styled(AnchorPresenter)`
  color: ${vc_color.link};
  text-decoration: none;
  ${media.Large`
    &:hover,
    &:active {
      color: ${vc_color.link_hover};
      text-decoration: none;
      .anchor-text{
        text-decoration: underline;
      }
    }
  `}
  &.side {
    color: ${vc_color.text};
    ${media.Large`
      &:hover{
        text-decoration: none;
        background: ${vc_color.side_bg_hover};
      }
    `}
    &.cat-active {
      background: ${vc_color.primary_light};
      font-weight: bold;
    }
  }
`

const Anchor = containPresenter(AnchorContainer, StyledAnchorPresenter)
export default Anchor
