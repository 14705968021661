import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Anchor from '../atoms/BaseAnchor'
import Txt from '../atoms/BaseTxt'

// template
const TheHeader = (props) => (
  <StaticQuery
    query={header}
    render={(data) => (
      <Header {...props}>
        <Inner className={props.innerCls ? props.innerCls : 'header-inner'}>
          <LogoLink to="/">
            <LogoImg
              image={data.logo.childImageSharp.gatsbyImageData}
              alt="バリューコマース アフィリエイト管理画面ヘルプ"
            />
            <LogoText type={'span'} role={'large'}>
              ヘルプ
            </LogoText>
          </LogoLink>
        </Inner>
      </Header>
    )}
  />
)

export default TheHeader

// script
export const header = graphql`
  {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 422, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`

// style
const Header = styled.header`
  align-items: center;
  background: #fff;
  border-bottom: 1px solid ${vc_color.primary};
  display: flex;
  height: ${vc_base.pixel`5.5`};
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 13;
  ${media.Large`
		height: ${vc_base.pixel`8`};
		position: static;
		top: unset;
		left: unset;
		z-index: 1;
	`}
`
const Inner = styled.div`
  text-align: center;
  ${media.Large`
		text-align: left;
	`}
`
const LogoLink = styled(Anchor)`
  align-items: center;
  display: flex;
  justify-content: center;
  ${media.Large`
    justify-content: flex-start;
	`}
`
const LogoImg = styled(GatsbyImage)`
  margin-top: 3px;
  width: 88px;
  ${media.Large`
    width: 130px;
	`}
`
const LogoText = styled(Txt)`
  color: #000;
  font-weight: 400;
  margin-left: 1em;
  ${media.Large`
    margin-top: -0.15em;
	`}
`
