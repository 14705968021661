import React from 'react'
import { containPresenter } from '../../utils/HoC'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'

// Container
export const HeadingContainer = ({ presenter, level = 2, ...props }) => {
  level = Math.max(1, Math.min(6, level))
  const tag = `h${level}`
  return presenter({ tag, ...props })
}

// Presenter
export const HeadingPresenter = ({ tag: Tag, className, ...props }) => {
  return <Tag className={[className].join(' ')} {...props} />
}

// style
const StyledHeadingPresenter = styled(HeadingPresenter)`
  &.title-page {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.35;
    ${media.Large`
      font-size: 2.4rem;
    `}
  }
  &.title-detail {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.35;
    ${media.Large`
      font-size: 3.2rem;
    `}
  }
  &.title-module {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.25;
    ${media.Large`
      font-size: 1.8rem;
    `}
  }
  &.title-section {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.25;
    ${media.Large`
      font-size: 2.0rem;
    `}
  }
`

const Heading = containPresenter(HeadingContainer, StyledHeadingPresenter)
export default Heading
