import React from 'react'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Txt from '../atoms/BaseTxt'
import Caption from '../atoms/BaseCaption'
import Bnr from '../molecules/Bnr'

// template
const TheSideBnr = () => (
  <BnrBox>
    <SideBnr
      className="base-text"
      type={'a'}
      href="//www.valuecommerce.ne.jp/stepup/guide/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <BnrTitle type={'span'}>管理画面ガイド</BnrTitle>
      <BnrTxt type={'span'}>
        アフィリエイト管理画面の使い方を分かりやすく説明します。
      </BnrTxt>
    </SideBnr>

    <SideBnr
      className="base-text"
      type={'a'}
      href="//www.valuecommerce.ne.jp/stepup/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <BnrTitle type={'span'}>ステップアップガイド</BnrTitle>
      <BnrTxt type={'span'}>
        アフィリエイトで稼いでいくための考え方や手法をご紹介します。
      </BnrTxt>
    </SideBnr>

    <SideBnr
      className="base-text"
      type={'a'}
      href="//www.valuecommerce.ne.jp/feature/webservice.html"
      target="_blank"
      rel="noopener noreferrer"
    >
      <BnrTitle type={'span'}>アフィリエイトAPI</BnrTitle>
      <BnrTxt type={'span'}>
        上級者の方は、コンテンツのリッチ化やマネタイズにご活用ください。
      </BnrTxt>
    </SideBnr>
  </BnrBox>
)

export default TheSideBnr

// style
const BnrBox = styled.div`
  > a {
    margin-top: ${vc_base.pixel};
    &:first-child {
      margin-top: ${vc_base.pixel`2`};
    }
  }
`
const SideBnr = styled(Bnr)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: ${vc_base.pixel`10`};
  transition: all ${vc_base.speed_quick} ease-out;
  ${media.Large`
		&:hover{
			opacity: 0.7;
		}
	`}
`
const BnrTitle = styled(Txt)`
  color: ${vc_color.link};
  display: block;
  font-weight: bold;
`
const BnrTxt = styled(Caption)`
  color: ${vc_color.text};
  display: block;
`
