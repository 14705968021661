import React from 'react'
import styled from 'styled-components'
import { vc_base, vc_color } from '../../assets/js/Brand'

// components
import Heading from '../atoms/BaseHeading'
import Txt from '../atoms/BaseTxt'
import Anchor from '../atoms/BaseAnchor'

import { connectHits, Highlight, Snippet } from 'react-instantsearch-dom'

// template
const SearchResultAlgolia = (props) => (
  <SearchResult className="search__list">
    {props.query.length > 0 && (
      <SearchResultTitle level={2} className="title-module">
        <SearchResultTitleInner>
          {props.query}
          <SearchResultTitleMini type={'span'} className="anchor-text">
            の検索結果
          </SearchResultTitleMini>
        </SearchResultTitleInner>
        <SearchResultTitleInner>
          <SearchResultTitleMini type={'span'} className="anchor-text">
            {props.hits.length}件
          </SearchResultTitleMini>
        </SearchResultTitleInner>
      </SearchResultTitle>
    )}

    {props.hits.length !== 0 ? (
      <SearchResultBox style={props.style}>
        {props.hits.map((hit) => (
          <SearchResultItem key={hit.objectID}>
            <SearchResultLink
              className="search__list_white search__list_non-decoration"
              to={`${hit.fields.slug}`}
            >
              <Txt type={'span'} className="anchor-text">
                <Highlight hit={hit} attribute="title" tagName="em" />
              </Txt>
              <Snippet attribute="excerpt" hit={hit} tagName="em" />
            </SearchResultLink>
          </SearchResultItem>
        ))}
      </SearchResultBox>
    ) : props.query.length > 0 ? (
      <SearchResultNoItem>
        <Txt>「{props.query}」に一致する情報は見つかりませんでした。</Txt>
      </SearchResultNoItem>
    ) : (
      ''
    )}
  </SearchResult>
)

export default connectHits(SearchResultAlgolia)

// style
const SearchResult = styled.div`
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
`
const SearchResultTitle = styled(Heading)`
  background: ${vc_color.bg_light};
  border-bottom: 1px solid ${vc_color.border};
  border-left: 1px solid ${vc_color.border};
  border-radius: ${vc_base.pixel`0.5`} ${vc_base.pixel`0.5`} 0 0;
  border-right: 1px solid ${vc_color.border};
  border-top: 1px solid ${vc_color.border};
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${vc_base.pixel};
  padding-left: ${vc_base.pixel`2.5`};
  padding-right: ${vc_base.pixel`2.5`};
  padding-top: ${vc_base.pixel};
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    left: ${vc_base.pixel};
    position: absolute;
  }
  &::before {
    border-bottom: 20px solid ${vc_color.bg_light};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 20px solid transparent;
    top: -39px;
    z-index: 2;
  }

  &::after {
    border-bottom: 21px solid ${vc_color.border};
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 21px solid transparent;
    margin-left: -1px;
    top: -41px;
    z-index: 1;
  }
`
const SearchResultTitleInner = styled.span`
  text-align: right;
  width: 3em;
  &:first-child {
    text-align: left;
    width: calc(100% - 3em);
  }
`
const SearchResultTitleMini = styled(Txt)`
  font-weight: normal;
`
const SearchResultBox = styled.ul`
  background: ${vc_color.light};
  border-bottom: 1px solid ${vc_color.border};
  border-left: 1px solid ${vc_color.border};
  border-radius: 0 0 ${vc_base.pixel`0.5`} ${vc_base.pixel`0.5`};
  border-right: 1px solid ${vc_color.border};
  overflow: auto;
`
const SearchResultItem = styled.li`
  border-top: 1px solid ${vc_color.border};
  &:first-child {
    border-top: none;
  }
`
const SearchResultNoItem = styled.div`
  background: ${vc_color.light};
  border-bottom: 1px solid ${vc_color.border};
  border-left: 1px solid ${vc_color.border};
  border-radius: 0 0 ${vc_base.pixel`0.5`} ${vc_base.pixel`0.5`};
  border-right: 1px solid ${vc_color.border};
  padding-bottom: ${vc_base.pixel`1.5`};
  padding-left: ${vc_base.pixel`2.5`};
  padding-right: ${vc_base.pixel`2.5`};
  padding-top: ${vc_base.pixel`1.5`};
`
const SearchResultLink = styled(Anchor)`
  display: block;
  padding-bottom: ${vc_base.pixel`1.5`};
  padding-left: ${vc_base.pixel`2.5`};
  padding-right: ${vc_base.pixel`2.5`};
  padding-top: ${vc_base.pixel`1.5`};
`
