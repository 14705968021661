import React from 'react'
import { containPresenter } from '../../utils/HoC'
import styled from 'styled-components'
import { media } from '../../assets/js/Helper'

// Container
export const CaptionContainer = ({ presenter, type = 'p', ...props }) => {
  const tag = type
  return presenter({ tag, ...props })
}

// Presenter
export const CaptionPresenter = ({ tag: Tag, className, ...props }) => {
  return <Tag className={[className].join(' ')} {...props} />
}

// style
const StyledCaptionPresenter = styled(CaptionPresenter)`
  font-size: 1.1rem;
  line-height: 1.35;
  ${media.Large`
    font-size: 1.3rem;
  `}
  &.note {
    display: block;
    padding-left: 2em;
    text-indent: -2em;
  }
`

const Caption = containPresenter(CaptionContainer, StyledCaptionPresenter)
export default Caption
