import { css } from 'styled-components'

/*Media Queries
--------------------------------------------------------------*/
export const media = {
  Minimum: (...args) => css`
    @media (min-width: 480px) {
      ${css(...args)}
    }
  `,
  Small: (...args) => css`
    @media (min-width: 576px) {
      ${css(...args)}
    }
  `,
  Medium: (...args) => css`
    @media (min-width: 768px) {
      ${css(...args)}
    }
  `,
  Large: (...args) => css`
    @media (min-width: 992px) {
      ${css(...args)}
    }
  `,
  Extra: (...args) => css`
    @media (min-width: 1200px) {
      ${css(...args)}
    }
  `,
}
