export const vc_base = {
  pixel: (args) => {
    if (!Array.isArray(args)) args = 1
    if (args[0] === '') args = 1
    return `${Math.floor(0.8 * Number(args) * 10) / 10}rem`
  },
  speed_quick: '250ms',
  speed_slow: '500ms',
}

export const vc_color = {
  dark: '#000000',
  light: '#ffffff',

  primary: '#52c4c5',
  primary_dark: '#006f70',
  primary_light: '#eef9f9',

  text: '#333333',
  text_gray: '#cccccc',

  link: '#0085cc',
  link_hover: '#005380',

  border: '#dddddd',
  bg_dark: '#eeeeee',
  bg_light: '#f9f9f9',

  side_text_hover: '#262626',
  side_bg_hover: '#eeeeee',
  side_bg_active: '#f5f5f5',

  tally: '#d01115',
  exclamation: '#0028a3',
}
