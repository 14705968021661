import { createGlobalStyle } from 'styled-components'
import { vc_base, vc_color } from './Brand'

export const GlobalStyle = createGlobalStyle`
	html, body, div, span, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	abbr, address, cite, code,
	del, dfn, em, img, ins, kbd, q, samp,
	small, strong, sub, sup, var,
	b, i,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section, summary,
	time, mark, audio, video {
		background:transparent;
		border:0;
		font-size:100%;
		margin:0;
		outline:0;
		padding:0;
		vertical-align:baseline;
	}

	html {
		font-size: 62.5%;
		min-height: 100%;
		position: relative;
		z-index: 1;
	}

	body {
		font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
		font-size: 10px;
		font-size: 1.0rem;
		line-height:1;
	}

	article,aside,details,figcaption,figure,
	footer,header,hgroup,menu,nav,section {
		display:block;
	}

	ol, ul {
		list-style: none;
	}

	blockquote, q {
		quotes:none;
	}

	blockquote::before, blockquote::after,
	q::before, q::after {
		content:'';
		content:none;
	}

	a {
		background:transparent;
		font-size:100%;
		margin:0;
		padding:0;
		vertical-align:baseline;
	}

	ins {
		background-color:#ff9;
		color:#000;
		text-decoration:none;
	}

	mark {
		background-color:#ff9;
		color:#000;
		font-style:italic;
		font-weight:bold;
	}

	del {
		text-decoration: line-through;
	}

	abbr[title], dfn[title] {
		border-bottom:1px dotted;
		cursor:help;
	}

	table {
		border-collapse:collapse;
		border-spacing:0;
	}

	hr {
		border:0;
		border-top:1px solid #ccc;
		display:block;
		height:1px;
		margin:1em 0;
		padding:0;
	}

	input, select {
		vertical-align:middle;
	}

	img {
		font-size: 0;
		line-height: 0;
		vertical-align: top;
	}
	*, *::before, *::after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
	}
	.clear {
		clear: both;
	}
	.clearfix::after {
		clear:both;
		content:".";
		display:block;
		height:0;
		visibility:hidden;
	}
	.clearfix {
		display: block;
		min-height:1%;  /* for IE 7 */
	}
	input::placeholder {
		color: ${vc_color.text_gray};
	}
	input::-ms-input-placeholder {
		color: ${vc_color.text_gray};
	}
	input:-ms-input-placeholder {
		color: ${vc_color.text_gray};
	}

	/* Hides from IE-mac */
	* html .clearfix { height: 1%; }

	/* End hide from IE-mac */

	/* bootstrap 書き換え
	-------------------------------------------------------------- */
	.btn-primary {
		background: ${vc_color.primary};
		border: none;
	}

	.btn-primary:hover, .btn-primary:focus,
	.btn-primary:active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:active.focus,
	.btn-primary.active, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.active.focus,
	.open > .dropdown-toggle.btn-primary,
	.open > .dropdown-toggle.btn-primary:hover,
	.open > .dropdown-toggle.btn-primary:focus,
	.open > .dropdown-toggle.btn-primary.focus,
	.btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover,
	.btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus,
	.btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus {
		background: ${vc_color.primary_dark};
	}

	.main {
		> section + section{
			margin-top: ${vc_base.pixel`5`};
		}
	}

	.ais-Highlight-highlighted {
		background: rgba(84,104,255,.1);
    color: #5468ff;
		font-style: normal;
		font-weight: 800;
	}
	em.ais-Snippet-highlighted {
		background: rgba(84,104,255,.1);
    color: #5468ff;
		font-style: normal;
		font-weight: 800;
	}
	.ais-Snippet {
		display: block;
	}
`
